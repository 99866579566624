import path from '@/api/path'
import service from '@/api/service'
// import renewModel from '@/views/model/deploy-model/renew-model.vue'

const { project } = path

export default {
  // 算力列表
  getResourceInfoList: (data) => {
    return service.get(project.getResourceInfoList, data)
  },
  // 模型列表
  queryprojectlist: (data) => {
    return service.post(project.queryprojectlist, data)
  },
  // 体验列表
  queryspacelist: (data) => {
    return service.post(project.spaceList, data)
  },
  //个人体验
  getPersonModelServings: (data) => {
    return service.post(project.getPersonModelServings, data)
  },
//个人收藏模型
getCollectedModelServings: (data) => {
    return service.post(project.getCollectedModelServings, data)
  },
  //统计模型访问次数
  modelServingAccessCount: (data) => {
    return service.post(project.modelServingAccessCount, data)
  },
  //创建API服务
  createModelServingAPI: (data) => {
    return service.post(project.createModelServingAPI, data)
  },
  // 体验列表
  querytopspacelist: (data) => {
    return service.post(project.spaceTopList, data)
  },
  getModelRelateModelServings: (data) => {
    return service.post(project.getModelRelateModelServings, data)
  },
  getModelImages: (data) => {
    return service.post(project.getModelImages, data)
  },
  getModelServingChargings: (data) => {
    return service.post(project.getModelServingChargings, data)
  },
  getAccountChargeOrders: (data) => {
    return service.post(project.getAccountChargeOrders, data)
  },
  startupModelServing: (data) => {
    return service.post(project.startupModelServing, data)
  },
  stopModelServing: (data) => {
    return service.post(project.stopModelServing, data)
  },
  // 创建体验
  createModelServing: (data) => {
    return service.post(project.createModelServing, data)
  },
  //续费功能
  renewModelServing: (data) => {
    return service.post(project.renewModelServing, data)
  },
    //添加apitoken
    addMSAPIKey: (data) => {
      return service.post(project.addMSAPIKey, data)
    },
    //获取服务器时间戳
    getCurrentMSTimeStamp:data=>{
      return service.post(project.getCurrentMSTimeStamp,data)
    },
   //获取apitoken列表
   getMSAPIKeys: (data) => {
    return service.post(project.getMSAPIKeys, data)
  },
   //删除apitoken
   delMSAPIKey: (data) => {
    return service.post(project.delMSAPIKey, data)
  },
  //设置默认token
  setDefaultMSAPIKey: (data) => {
    return service.post(project.setDefaultMSAPIKey, data)
  },
  // 个人账户信息
  getAccountInfo: (data) => {
    return service.post(project.getAccountInfo, data)
  },
  uploadMSImage: (data) => {
    return service.post(project.uploadMSImage, data)
  },
  uploadMSChat: (data) => {
    return service.post(project.uploadMSChat, data)
  },
  //微信支付接口
  wxpayMent: (data) => {
    return service.post(project.wxpayMent, data)
  },
  //支付结果查询
  checkPay: (data) => {
    return service.get(project.checkPay+data)
  },
  //获取时间戳
  getCurrentMSTimeStamp: (data) => {
    return service.post(project.getCurrentMSTimeStamp, data)
  },
  //服务公开
  setPersonMSVisable:data=>{
    return service.post(project.setPersonMSVisable,data)
  },
  //启动失败的开发环境重试
  reInitModelDev:data=>{
    return service.post(project.reInitModelDev,data)
  },
  //获取个人模型服务订单
  getModelServingOrders:data=>{
    return service.post(project.getModelServingOrders,data)
  },
  // 模型服务点赞
  modelservingzan: (data) => {
    return service.post(project.modelservingzan, data)
  },
  // 模型服务收藏
  modelservingcollect: (data) => {
    return service.post(project.modelservingcollect, data)
  },
  modelservingcancelzan: (data) => {
    return service.post(project.modelservingcancelzan, data)
  },
  modelservingcancelcollect: (data) => {
    return service.post(project.modelservingcancelcollect, data)
  },
  getModelServing: (data) => {
    return service.post(project.getModelServing, data)
  },
  getxpuCharg: (data) => {
    return service.post(project.getxpuCharg, data)
  },
  modelservinghaszan: (data) => {
    return service.post(project.modelservinghaszan, data)
  },
  modelservinghascollect: (data) => {
    return service.post(project.modelservinghascollect, data)
  },
  /* 创建模型 */
  // 基础信息
  addModel: (data) => {
    return service.post(project.addModel, data)
  },
  
  // 设置属性
  setAttr: (data) => {
    return service.post(project.setAttr, data)
  },
  // 添加标签
  addlabel: (data) => {
    return service.post(project.addlabel, data)
  },
  // 关联模型数据集
  related: (data) => {
    return service.post(project.related, data)
  },
  // 取消关联模型数据集
  cancelrelated: (data) => {
    return service.post(project.cancelrelated, data)
  },
  // 删除项目
  delproject: (data) => {
    return service.post(project.delproject, data)
  },
  // 点赞
  projectzan: (data) => {
    return service.post(project.projectzan, data)
  },
  // 取消点赞
  projectcancelzan: (data) => {
    return service.post(project.projectcancelzan, data)
  },
  // 收藏
  projectcollect: (data) => {
    return service.post(project.projectcollect, data)
  },
  // 取消收藏
  projectcancelcollect: (data) => {
    return service.post(project.projectcancelcollect, data)
  },
  /* 模型详情 */
  // 查询项目详情
  queryprojectinfo: (data) => {
    return service.post(project.queryprojectinfo, data)
  },
  // 查询项目关联列表
  relatelist: (data) => {
    return service.post(project.relatelist, data)
  },
  // 查询项目分支列表
  branchlist: (data) => {
    return service.post(project.branchlist, data)
  },
  // 文件提交commit记录
  commithistory: (data) => {
    return service.post(project.commithistory, data)
  },
  // 展示文件
  listfiles: (data) => {
    return service.post(project.listfiles, data)
  },
  // 创建问题
  createissue: (data) => {
    return service.post(project.createissue, data)
  },
  // 问题列表
  issuelist: (data) => {
    return service.post(project.issuelist, data)
  },
  // 创建讨论
  creatediscuss: (data) => {
    return service.post(project.creatediscuss, data)
  },
  // 讨论列表
  discusslist: (data) => {
    return service.post(project.discusslist, data)
  },
  // 删除讨论
  removediscuss: (data) => {
    return service.post(project.removediscuss, data)
  },
  // 增加分支
  addbranch: (data) => {
    return service.post(project.addbranch, data)
  },
  // 增加分支标签
  addtag: (data) => {
    return service.post(project.addtag, data)
  },
  // 查询项目网盘url
  getProjectWangpanurl: (data) => {
    return service.post(project.getProjectWangpanurl, data)
  },
  //动态获取xpu信心
  
  getXPUInfosByImageId: (data) => {
    return service.post(project.getXPUInfosByImageId, data)
  },
  //取消未生效的订单
  cancleModelServingOrder:data=>{
    return service.post(project.cancleModelServingOrder, data)
  },
  //创建开发环境
  createModelDevAPI:data=>{
    return service.post(project.createModelDevAPI, data)
  },
  //开发环境详情
  getModelDevDetail:data=>{
    return service.post(project.getModelDevDetail, data)
  },
  //获取开发环境订单
  getModelDevOrder:data=>{
    return service.post(project.getModelDevOrder, data)
  },
  //获取开发环境消费记录
  getModelDevChargings:data=>{
    return service.post(project.getModelDevChargings, data)
  },
   //获取实时日志
   getRealTimeLog:data=>{
    return service.post(project.getRealTimeLog, data)
  },
   //获取开发环境历史日志
   getHistoryTimeLog:data=>{
    return service.post(project.getHistoryTimeLog, data)
  },
   //停止开发环境
   stopModelDev:data=>{
    return service.post(project.stopModelDev, data)
  },
     //重新启动
     reStartModelDev:data=>{
      return service.post(project.reStartModelDev, data)
    },
  //停止开发环境
  setUserStorage:data=>{
    return service.post(project.setUserStorage, data)
  },
  //获取训练用镜像列表
  getModelImagesForTrain:data=>{
    let framework={
      framework_code:'324werffsfd3'//查询框架关联的开发环境镜像
    }
    return service.post(project.getModelImagesForTrain, framework)
  },
  //获取训练用镜像列表
  getGpuUtilization:data=>{
    return service.post(project.getGpuUtilization, data)
  },
    //获取训练用镜像列表
    reportUserClickWCDC:data=>{
      return service.post(project.reportUserClickWCDC, data)
    },
}
