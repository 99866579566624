import path from '@/api/path'
import service from '@/api/service'
// import renewModel from '@/views/model/deploy-model/renew-model.vue'

const { storage } = path


export default {
  //生产环境
  getShareFileList:data=>{
    console.log(data);
    
    let url =storage.getUserDir +'/'+data +'/storage/api/v1/getShareFileList'
    return service.post(url, )
  },
  //获取开发环境指定的目录的列表
  getShareModelDir:data=>{
    let url =storage.getUserDir +'/'+data.cluster_code +'/storage/api/v1/getShareModelDir'
    delete data.cluster_code
    return service.post(url, data)
  },
   //获取个人目录
   getUserDir:data=>{
    let url =storage.getUserDir +'/'+data.cluster_code +'/storage/api/v1/getUserDir'
    delete data.cluster_code
    return service.post(url, data)
  },
}