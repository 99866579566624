import system from './modules/system'
import user from './modules/user'
import project from './modules/project'
import collect from './modules/collect'
import notice from './modules/notice'
import organize from './modules/organize'
import resource from './modules/resource'
import file from './modules/file'
import comment from './modules/comment'
import storage from './modules/storage'

export default {
  system,
  user,
  project,
  collect,
  notice,
  organize,
  resource,
  file,
  comment,
  storage
}
