import store from '@/store'
import api from '@/api'
import array2Tree from '@/utils/arrayToTree'

const { system, organize } = api
const { getOptions, getModelImages,getTimeRange,getXPUInfo,getModelImagesForTrains } = system

// 模型类型
export const getModelType = (data) => {
  getOptions(data).then((res) => {
    store.dispatch('selectOptions/setModelType', res.data)

    const arr = res.data.map((item) => {
      let logoName = 'other',
        colorClass = 'shijue'
      switch (item.name) {
        case '视觉模型':
          logoName = 'shijue'
          colorClass = 'view'
          break
        case 'NLP模型':
          logoName = 'NLP'
          colorClass = 'nlp'
          break
        case '语音模型':
          logoName = 'yuyin'
          colorClass = 'sound'
          break
        case '多模态模型':
          logoName = 'duomotai'
          colorClass = 'multimode'
          break
        case '科学计算模型':
          logoName = 'NLP'
          colorClass = 'scientific-calculation'
          break
        case '时间序列模型':
          logoName = 'time'
          break
        case '增强学习模型':
          logoName = 'study'
          break
        case '其他':
          logoName = 'other'
          break
        case '应用':
          logoName = 'shijue'
          colorClass = 'view'
          break
        case '应用框架':
          logoName = 'NLP'
          colorClass = 'nlp'
          break
        case '模型与算法':
          logoName = 'yuyin'
          colorClass = 'sound'
          break
        case '基础设施类':
          logoName = 'duomotai'
          colorClass = 'multimode'
          break
        default:
          break
      }

      return {
        label: item.name,
        value: item.id,
        pid: item.pid,
        logo: logoName ? require(`@/assets/images/${logoName}.png`) : null,
        colorClass,
        more: false
      }
    })
    const tree = array2Tree(arr, {
      id: 'value',
      parentId: 'pid',
      children: 'children'
    })
    store.dispatch('selectOptions/setModelTypeTree', tree)
  })
}

// 发布者（当前用户&当前用户创建和加入的组织，需建立在登录的基础上）
export const getPublisher = () => {
  if (store.state.system.userInfo) {
    let arr = []
    const { username: name } = store.state.system.userInfo
    const { username: org_en } = store.state.system.userInfo
    arr.push({ id: 0, name, org_en }) // 当前用户(当前用户的所有者id设为0，用于和组织id区分开)
    organize
      .list({
        search_type: '1',// 1是我加入的 2是我创建的
    
        page_num: 1,
        page_size: 999999
      })
      .then((res1) => {
        if (res1.message === 'Success') {
          arr.push(
            ...res1.data.orgs.map((item) => {
              return {
                name: item.org_name,
                org_en: item.org_en,
                id: item.id,
                org_type: item.org_type // 说明是组织
              }
            })
          ) // 我加入的组织
          organize
            .list({
              search_type: '2',// 1是我加入的 2是我创建的
   
              page_num: 1,
              page_size: 999999
            })
            .then((res2) => {
              if (res2.message === 'Success') {
                arr.push(
                  ...res2.data.orgs.map((item) => {
                    return {
                      name: item.org_name,
                      org_en: item.org_en,
                      id: item.id,
                      org_type: item.org_type
                    }
                  })
                ) // 我创建的组织
                arr = arr.filter(
                  (
                    (s) => (a) =>
                      !s.has(a.id) && s.add(a.id)
                  )(new Set(arr))
                ) // 去重
                store.dispatch('selectOptions/setOwner', arr) // 存入vuex
              }
            })
        }
      })
  }
}

// 支持框架
export const getFramework = () => {
  getOptions({ key: 'framework' }).then((res) => {
    store.dispatch('selectOptions/setFrameWork', res.data)
  })
}

// 应用场景
export const getApplicationScenario = () => {
  getOptions({ key: 'scene' }).then((res) => {
    store.dispatch('selectOptions/setApplicationScenario', res.data)
  })
}

// 硬件
export const getSupportHardware = () => {
  getOptions({ key: 'hardware' }).then((res) => {
    store.dispatch('selectOptions/setSupportHardware', res.data)
  })
}

// 软件
export const getSupportToolSoftware = () => {
  getOptions({ key: 'solftware' }).then((res) => {
    store.dispatch('selectOptions/setSupportToolSoftware', res.data)
  })
}

// 语言
export const getLanguage = () => {
  getOptions({ key: 'language' }).then((res) => {
    store.dispatch('selectOptions/setLanguage', res.data)
  })
}

// 标签
export const getLabel = () => {
  getOptions({ key: 'label' }).then((res) => {
    store.dispatch('selectOptions/setLabel', res.data)
  })
}
// 协议/许可
export const getLicense = () => {
  getOptions({ key: 'license' }).then((res) => {
    store.dispatch('selectOptions/setLicense', res.data)
  })
}

//镜像列表
export const getModelImage = () => {
  getModelImages({}).then((res) => {
    store.dispatch('selectOptions/setModelImages', res.data)
  })
}
//镜像列表
export const getModelImagesForTrain = () => {
  getModelImagesForTrains({}).then((res) => {
    store.dispatch('selectOptions/getModelImagesForTrains', res.data)
  })
}
//获取时间范围可选列表
export const getTimeRangeList = () => {
  getTimeRange({}).then((res) => {
    store.dispatch('selectOptions/setTimeRangeList', res.data)
  })
}
//获取xpu信息列表
export const getXPUInfoList = () => {
  getXPUInfo({}).then((res) => {
    store.dispatch('selectOptions/setXPUInfoList', res.data)
  })
}
export const getServerType = () => {
  getOptions({ key: 'category', project_type: 'C' }).then((res) => {
    store.dispatch('selectOptions/setServerTypeList', res.data)
  })
}

export const init = () => {
  // 是否公开
  store.dispatch('selectOptions/setIsOpen', [
    {
      name: '公开',
      id: 1
    },
    {
      name: '私有',
      id: 0
    }
  ])

  // 是否支持
  store.dispatch('selectOptions/setIsSupport', [
    {
      name: '支持',
      id: 1
    },
    {
      name: '不支持',
      id: 0
    }
  ])

  // 性别
  store.dispatch('selectOptions/setSex', [
    { name: '男', id: '0' },
    { name: '女', id: '1' }
  ])

  // 新建组织-组织类型
  store.dispatch('selectOptions/setOrgType', [
    { name: '企业', id: '1' },
    { name: '高校', id: '2' },
    { name: '科研院所', id: '3' },
    { name: '其他', id: '4' }
  ])

  // 学历
  store.dispatch('selectOptions/setBackgroundEdu', [
    { name: '博士', id: 4 },
    { name: '硕士', id: 3 },
    { name: '学士', id: 2 },
    { name: '专科', id: 1 }
  ])

  getPublisher()
  getFramework()
  getApplicationScenario()
  getSupportHardware()
  getSupportToolSoftware()
  getLanguage()
  getLabel()
  getLicense()
  getModelImage()
  getModelImagesForTrain()
  getTimeRangeList()
  getXPUInfoList()
  getServerType()
}
